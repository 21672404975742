<template>
  <RouterView />
</template>

<script setup lang="ts">
import '@fontsource-variable/noto-sans-jp'; // Supports weights 100-900
import '@/styles/global.scss';

import { RouterView } from 'vue-router';

onMounted(initLocale);

function initLocale() {
  const { tenant } = useStores('tenant');

  // This will set the locale at the start of the UI session.
  // This step is necessary at load time to set the HTML lang attribute, vue-i18n starting default, etc.
  // This will pull from the user's browser, if necessary (either local storage or the browser's locale)
  tenant.setUILocale(tenant.locale);
}

// Keypress handling
// We include this here at the root component only and because we only want one keypress handler for the whole app.
// Attaching handlers from children components is done via `inject`/`provide`
provideEscapeKeyHandler();

// This tracks whether or not the user is in the middle of resolving an IME composition (e.g. Japanese/Chinese input on a keyboard)
provideIMEActiveTracking();
</script>
