<template>
  <div class="TextareaHolder">
    <textarea
      v-bind="$attrs"
      :id="name"
      v-model="validatedValue"
      :aria-describedby="
        !showError && (showHint || (hasCounter && maxlength)) ? ariaLabel : undefined
      "
      :aria-valid="meta.touched ? meta.valid : undefined"
      :aria-invalid="meta.touched ? !meta.valid : undefined"
      :aria-errormessage="showError ? ariaLabel : undefined"
      :name
      class="Textarea"
      :class="{ 'Textarea-alt': alt, 'Textarea-error': showError, 'Textarea-success': meta.valid }"
      :placeholder="placeholder ? $t(placeholder) : undefined"
      :rows
      :disabled
      @input="handleChange"
      @blur="handleBlur"
      @keyup.enter="$emit('submit', validatedValue ?? '')"
    />

    <HelpMessage
      v-if="showHint || (!hasCounter && showError)"
      :id="ariaLabel"
      :type="showError ? 'error' : 'info'"
    >
      <template v-if="hint">{{ $t(hint) }}</template>
      <template v-else-if="error">{{ error }}</template>
      <template v-else>{{ errorMessage }}</template>
    </HelpMessage>

    <CharactersCounter v-if="maxlength" :value="model" :maxlength />
  </div>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';

import { useField } from 'vee-validate';

import { toTypedSchema } from '@vee-validate/zod';
import { z, ZodString } from 'zod';

export type Props = {
  name: string;
  placeholder?: TranslationKey | '';
  error?: string | undefined;
  hint?: TranslationKey | undefined;
  rows?: number;
  alt?: boolean;
  disabled?: boolean;
  maxlength?: number;
  hasCounter?: boolean;
  schema?: ZodString;
};

const props = withDefaults(defineProps<Props>(), {
  rows: 4,
  maxlength: undefined,
  schema: undefined,
  error: undefined,
  hint: undefined,
  placeholder: '',
});

defineEmits<{
  submit: [val: string];
}>();

defineOptions({
  inheritAttrs: false,
});

const schema = computed(() => {
  const s = props.schema ?? z.string();
  if (props.maxlength) return toTypedSchema(s.max(props.maxlength));
  else return toTypedSchema(s);
});

const model = defineModel<string | null>({ default: '' });

const {
  value: validatedValue,
  handleBlur,
  handleChange,
  errorMessage,
  meta,
} = useField(() => props.name, schema, {
  initialValue: model,
  syncVModel: true,
});

const showError = computed(() => {
  return (
    !!props.error ||
    (props.hasCounter && props.maxlength
      ? validatedValue.value
        ? validatedValue.value.length > props.maxlength
        : ''
      : false) ||
    (!!errorMessage && meta.touched && !meta.valid)
  );
});

const showHint = computed(() => {
  return !!props.hint && !meta.valid;
});

const ariaLabel = computed(() => {
  return showHint ? `${props.name}-inputHelp` : showError ? `${props.name}-inputError` : '';
});
</script>

<style scoped lang="scss">
.TextareaHolder {
  width: 100%;
  line-height: 0;
}

.Textarea {
  border: 1px solid black(12);
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  resize: vertical;
  background-color: neutral(50);
  outline: none;

  @include setTransition(border);

  &:hover {
    border: 1px solid black(60);
  }
  &:focus {
    border: 1px solid primary(700);
  }
  &:disabled {
    border: 1px solid neutral(50);
    background-color: neutral(50);
    color: neutral(350);

    &::placeholder {
      color: neutral(350);
    }
  }

  &-alt {
    background-color: white();
  }

  &-error {
    border-color: red(400);
  }
  &-success {
    background-color: white();
  }
}
</style>
