export const WindowsTimezones = [
  {
    name: 'Dateline Standard Time',
    label: 'International Date Line West',
    id: 'Etc/GMT+12',
  },
  {
    name: 'UTC-11',
    label: 'Coordinated Universal Time-11',
    id: 'Etc/GMT+11',
  },
  {
    name: 'Aleutian Standard Time',
    label: 'Aleutian Islands',
    id: 'America/Adak',
  },
  {
    name: 'Hawaiian Standard Time',
    label: 'Hawaii',
    id: 'Pacific/Honolulu',
  },
  {
    name: 'Marquesas Standard Time',
    label: 'Marquesas Islands',
    id: 'Pacific/Marquesas',
  },
  {
    name: 'Alaskan Standard Time',
    label: 'Alaska',
    id: 'America/Anchorage',
  },
  {
    name: 'UTC-09',
    label: 'Coordinated Universal Time-09',
    id: 'Etc/GMT+9',
  },
  {
    name: 'Pacific Standard Time (Mexico)',
    label: 'Baja California',
    id: 'America/Tijuana',
  },
  {
    name: 'UTC-08',
    label: 'Coordinated Universal Time-08',
    id: 'Etc/GMT+8',
  },
  {
    name: 'Pacific Standard Time',
    label: 'Pacific Time (US & Canada)',
    id: 'America/Los_Angeles',
  },
  {
    name: 'US Mountain Standard Time',
    label: 'Arizona',
    id: 'America/Phoenix',
  },
  {
    name: 'Mountain Standard Time (Mexico)',
    label: 'Chihuahua, La Paz, Mazatlan',
    id: 'America/Mazatlan',
  },
  {
    name: 'Mountain Standard Time',
    label: 'Mountain Time (US & Canada)',
    id: 'America/Denver',
  },
  {
    name: 'Yukon Standard Time',
    label: 'Yukon',
    id: 'America/Whitehorse',
  },
  {
    name: 'Central America Standard Time',
    label: 'Central America',
    id: 'America/Guatemala',
  },
  {
    name: 'Central Standard Time',
    label: 'Central Time (US & Canada)',
    id: 'America/Chicago',
  },
  {
    name: 'Easter Island Standard Time',
    label: 'Easter Island',
    id: 'Pacific/Easter',
  },
  {
    name: 'Central Standard Time (Mexico)',
    label: 'Guadalajara, Mexico City, Monterrey',
    id: 'America/Mexico_City',
  },
  {
    name: 'Canada Central Standard Time',
    label: 'Saskatchewan',
    id: 'America/Regina',
  },
  {
    name: 'SA Pacific Standard Time',
    label: 'Bogota, Lima, Quito, Rio Branco',
    id: 'America/Bogota',
  },
  {
    name: 'Eastern Standard Time (Mexico)',
    label: 'Chetumal',
    id: 'America/Cancun',
  },
  {
    name: 'Eastern Standard Time',
    label: 'Eastern Time (US & Canada)',
    id: 'America/New_York',
  },
  {
    name: 'Haiti Standard Time',
    label: 'Haiti',
    id: 'America/Port-au-Prince',
  },
  {
    name: 'Cuba Standard Time',
    label: 'Havana',
    id: 'America/Havana',
  },
  {
    name: 'US Eastern Standard Time',
    label: 'Indiana (East)',
    id: 'America/Indianapolis',
  },
  {
    name: 'Turks And Caicos Standard Time',
    label: 'Turks and Caicos',
    id: 'America/Grand_Turk',
  },
  {
    name: 'Paraguay Standard Time',
    label: 'Asuncion',
    id: 'America/Asuncion',
  },
  {
    name: 'Atlantic Standard Time',
    label: 'Atlantic Time (Canada)',
    id: 'America/Halifax',
  },
  {
    name: 'Venezuela Standard Time',
    label: 'Caracas',
    id: 'America/Caracas',
  },
  {
    name: 'Central Brazilian Standard Time',
    label: 'Cuiaba',
    id: 'America/Cuiaba',
  },
  {
    name: 'SA Western Standard Time',
    label: 'Georgetown, La Paz, Manaus, San Juan',
    id: 'America/La_Paz',
  },
  {
    name: 'Pacific SA Standard Time',
    label: 'Santiago',
    id: 'America/Santiago',
  },
  {
    name: 'Newfoundland Standard Time',
    label: 'Newfoundland',
    id: 'America/St_Johns',
  },
  {
    name: 'Tocantins Standard Time',
    label: 'Araguaina',
    id: 'America/Araguaina',
  },
  {
    name: 'E. South America Standard Time',
    label: 'Brasilia',
    id: 'America/Sao_Paulo',
  },
  {
    name: 'SA Eastern Standard Time',
    label: 'Cayenne, Fortaleza',
    id: 'America/Cayenne',
  },
  {
    name: 'Argentina Standard Time',
    label: 'City of Buenos Aires',
    id: 'America/Buenos_Aires',
  },
  {
    name: 'Greenland Standard Time',
    label: 'Greenland',
    id: 'America/Godthab',
  },
  {
    name: 'Montevideo Standard Time',
    label: 'Montevideo',
    id: 'America/Montevideo',
  },
  {
    name: 'Magallanes Standard Time',
    label: 'Punta Arenas',
    id: 'America/Punta_Arenas',
  },
  {
    name: 'Saint Pierre Standard Time',
    label: 'Saint Pierre and Miquelon',
    id: 'America/Miquelon',
  },
  {
    name: 'Bahia Standard Time',
    label: 'Salvador',
    id: 'America/Bahia',
  },
  {
    name: 'UTC-02',
    label: 'Coordinated Universal Time-02',
    id: 'Etc/GMT+2',
  },
  {
    name: 'Azores Standard Time',
    label: 'Azores',
    id: 'Atlantic/Azores',
  },
  {
    name: 'Cape Verde Standard Time',
    label: 'Cabo Verde Is.',
    id: 'Atlantic/Cape_Verde',
  },
  {
    name: 'UTC',
    label: 'Coordinated Universal Time',
    id: 'Etc/UTC',
  },
  {
    name: 'GMT Standard Time',
    label: 'Dublin, Edinburgh, Lisbon, London',
    id: 'Europe/London',
  },
  {
    name: 'Greenwich Standard Time',
    label: 'Monrovia, Reykjavik',
    id: 'Atlantic/Reykjavik',
  },
  {
    name: 'Sao Tome Standard Time',
    label: 'Sao Tome',
    id: 'Africa/Sao_Tome',
  },
  {
    name: 'Morocco Standard Time',
    label: 'Casablanca',
    id: 'Africa/Casablanca',
  },
  {
    name: 'W. Europe Standard Time',
    label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    id: 'Europe/Berlin',
  },
  {
    name: 'Central Europe Standard Time',
    label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    id: 'Europe/Budapest',
  },
  {
    name: 'Romance Standard Time',
    label: 'Brussels, Copenhagen, Madrid, Paris',
    id: 'Europe/Paris',
  },
  {
    name: 'Central European Standard Time',
    label: 'Sarajevo, Skopje, Warsaw, Zagreb',
    id: 'Europe/Warsaw',
  },
  {
    name: 'W. Central Africa Standard Time',
    label: 'West Central Africa',
    id: 'Africa/Lagos',
  },
  {
    name: 'Jordan Standard Time',
    label: 'Amman',
    id: 'Asia/Amman',
  },
  {
    name: 'GTB Standard Time',
    label: 'Athens, Bucharest',
    id: 'Europe/Bucharest',
  },
  {
    name: 'Middle East Standard Time',
    label: 'Beirut',
    id: 'Asia/Beirut',
  },
  {
    name: 'Egypt Standard Time',
    label: 'Cairo',
    id: 'Africa/Cairo',
  },
  {
    name: 'E. Europe Standard Time',
    label: 'Chisinau',
    id: 'Europe/Chisinau',
  },
  {
    name: 'Syria Standard Time',
    label: 'Damascus',
    id: 'Asia/Damascus',
  },
  {
    name: 'West Bank Standard Time',
    label: 'Gaza, Hebron',
    id: 'Asia/Hebron',
  },
  {
    name: 'South Africa Standard Time',
    label: 'Harare, Pretoria',
    id: 'Africa/Johannesburg',
  },
  {
    name: 'FLE Standard Time',
    label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    id: 'Europe/Kiev',
  },
  {
    name: 'Israel Standard Time',
    label: 'Jerusalem',
    id: 'Asia/Jerusalem',
  },
  {
    name: 'South Sudan Standard Time',
    label: 'Juba',
    id: 'Africa/Juba',
  },
  {
    name: 'Kaliningrad Standard Time',
    label: 'Kaliningrad',
    id: 'Europe/Kaliningrad',
  },
  {
    name: 'Sudan Standard Time',
    label: 'Khartoum',
    id: 'Africa/Khartoum',
  },
  {
    name: 'Libya Standard Time',
    label: 'Tripoli',
    id: 'Africa/Tripoli',
  },
  {
    name: 'Namibia Standard Time',
    label: 'Windhoek',
    id: 'Africa/Windhoek',
  },
  {
    name: 'Arabic Standard Time',
    label: 'Baghdad',
    id: 'Asia/Baghdad',
  },
  {
    name: 'Turkey Standard Time',
    label: 'Istanbul',
    id: 'Europe/Istanbul',
  },
  {
    name: 'Arab Standard Time',
    label: 'Kuwait, Riyadh',
    id: 'Asia/Riyadh',
  },
  {
    name: 'Belarus Standard Time',
    label: 'Minsk',
    id: 'Europe/Minsk',
  },
  {
    name: 'Russian Standard Time',
    label: 'Moscow, St. Petersburg',
    id: 'Europe/Moscow',
  },
  {
    name: 'E. Africa Standard Time',
    label: 'Nairobi',
    id: 'Africa/Nairobi',
  },
  {
    name: 'Iran Standard Time',
    label: 'Tehran',
    id: 'Asia/Tehran',
  },
  {
    name: 'Arabian Standard Time',
    label: 'Abu Dhabi, Muscat',
    id: 'Asia/Dubai',
  },
  {
    name: 'Astrakhan Standard Time',
    label: 'Astrakhan, Ulyanovsk',
    id: 'Europe/Astrakhan',
  },
  {
    name: 'Azerbaijan Standard Time',
    label: 'Baku',
    id: 'Asia/Baku',
  },
  {
    name: 'Russia Time Zone 3',
    label: 'Izhevsk, Samara',
    id: 'Europe/Samara',
  },
  {
    name: 'Mauritius Standard Time',
    label: 'Port Louis',
    id: 'Indian/Mauritius',
  },
  {
    name: 'Saratov Standard Time',
    label: 'Saratov',
    id: 'Europe/Saratov',
  },
  {
    name: 'Georgian Standard Time',
    label: 'Tbilisi',
    id: 'Asia/Tbilisi',
  },
  {
    name: 'Volgograd Standard Time',
    label: 'Volgograd',
    id: 'Europe/Volgograd',
  },
  {
    name: 'Caucasus Standard Time',
    label: 'Yerevan',
    id: 'Asia/Yerevan',
  },
  {
    name: 'Afghanistan Standard Time',
    label: 'Kabul',
    id: 'Asia/Kabul',
  },
  {
    name: 'West Asia Standard Time',
    label: 'Ashgabat, Tashkent',
    id: 'Asia/Tashkent',
  },
  {
    name: 'Ekaterinburg Standard Time',
    label: 'Ekaterinburg',
    id: 'Asia/Yekaterinburg',
  },
  {
    name: 'Pakistan Standard Time',
    label: 'Islamabad, Karachi',
    id: 'Asia/Karachi',
  },
  {
    name: 'Qyzylorda Standard Time',
    label: 'Qyzylorda',
    id: 'Asia/Qyzylorda',
  },
  {
    name: 'India Standard Time',
    label: 'Chennai, Kolkata, Mumbai, New Delhi',
    id: 'Asia/Calcutta',
  },
  {
    name: 'Sri Lanka Standard Time',
    label: 'Sri Jayawardenepura',
    id: 'Asia/Colombo',
  },
  {
    name: 'Nepal Standard Time',
    label: 'Kathmandu',
    id: 'Asia/Katmandu',
  },
  {
    name: 'Central Asia Standard Time',
    label: 'Astana',
    id: 'Asia/Bishkek',
  },
  {
    name: 'Bangladesh Standard Time',
    label: 'Dhaka',
    id: 'Asia/Dhaka',
  },
  {
    name: 'Omsk Standard Time',
    label: 'Omsk',
    id: 'Asia/Omsk',
  },
  {
    name: 'Myanmar Standard Time',
    label: 'Yangon (Rangoon)',
    id: 'Asia/Rangoon',
  },
  {
    name: 'SE Asia Standard Time',
    label: 'Bangkok, Hanoi, Jakarta',
    id: 'Asia/Bangkok',
  },
  {
    name: 'Altai Standard Time',
    label: 'Barnaul, Gorno-Altaysk',
    id: 'Asia/Barnaul',
  },
  {
    name: 'W. Mongolia Standard Time',
    label: 'Hovd',
    id: 'Asia/Hovd',
  },
  {
    name: 'North Asia Standard Time',
    label: 'Krasnoyarsk',
    id: 'Asia/Krasnoyarsk',
  },
  {
    name: 'N. Central Asia Standard Time',
    label: 'Novosibirsk',
    id: 'Asia/Novosibirsk',
  },
  {
    name: 'Tomsk Standard Time',
    label: 'Tomsk',
    id: 'Asia/Tomsk',
  },
  {
    name: 'China Standard Time',
    label: 'Beijing, Chongqing, Hong Kong, Urumqi',
    id: 'Asia/Shanghai',
  },
  {
    name: 'North Asia East Standard Time',
    label: 'Irkutsk',
    id: 'Asia/Irkutsk',
  },
  {
    name: 'Singapore Standard Time',
    label: 'Kuala Lumpur, Singapore',
    id: 'Asia/Singapore',
  },
  {
    name: 'W. Australia Standard Time',
    label: 'Perth',
    id: 'Australia/Perth',
  },
  {
    name: 'Taipei Standard Time',
    label: 'Taipei',
    id: 'Asia/Taipei',
  },
  {
    name: 'Ulaanbaatar Standard Time',
    label: 'Ulaanbaatar',
    id: 'Asia/Ulaanbaatar',
  },
  {
    name: 'Aus Central W. Standard Time',
    label: 'Eucla',
    id: 'Australia/Eucla',
  },
  {
    name: 'Transbaikal Standard Time',
    label: 'Chita',
    id: 'Asia/Chita',
  },
  {
    name: 'Tokyo Standard Time',
    label: 'Osaka, Sapporo, Tokyo',
    id: 'Asia/Tokyo',
  },
  {
    name: 'North Korea Standard Time',
    label: 'Pyongyang',
    id: 'Asia/Pyongyang',
  },
  {
    name: 'Korea Standard Time',
    label: 'Seoul',
    id: 'Asia/Seoul',
  },
  {
    name: 'Yakutsk Standard Time',
    label: 'Yakutsk',
    id: 'Asia/Yakutsk',
  },
  {
    name: 'Cen. Australia Standard Time',
    label: 'Adelaide',
    id: 'Australia/Adelaide',
  },
  {
    name: 'AUS Central Standard Time',
    label: 'Darwin',
    id: 'Australia/Darwin',
  },
  {
    name: 'E. Australia Standard Time',
    label: 'Brisbane',
    id: 'Australia/Brisbane',
  },
  {
    name: 'AUS Eastern Standard Time',
    label: 'Canberra, Melbourne, Sydney',
    id: 'Australia/Sydney',
  },
  {
    name: 'West Pacific Standard Time',
    label: 'Guam, Port Moresby',
    id: 'Pacific/Port_Moresby',
  },
  {
    name: 'Tasmania Standard Time',
    label: 'Hobart',
    id: 'Australia/Hobart',
  },
  {
    name: 'Vladivostok Standard Time',
    label: 'Vladivostok',
    id: 'Asia/Vladivostok',
  },
  {
    name: 'Lord Howe Standard Time',
    label: 'Lord Howe Island',
    id: 'Australia/Lord_Howe',
  },
  {
    name: 'Bougainville Standard Time',
    label: 'Bougainville Island',
    id: 'Pacific/Bougainville',
  },
  {
    name: 'Russia Time Zone 10',
    label: 'Chokurdakh',
    id: 'Asia/Srednekolymsk',
  },
  {
    name: 'Magadan Standard Time',
    label: 'Magadan',
    id: 'Asia/Magadan',
  },
  {
    name: 'Norfolk Standard Time',
    label: 'Norfolk Island',
    id: 'Pacific/Norfolk',
  },
  {
    name: 'Sakhalin Standard Time',
    label: 'Sakhalin',
    id: 'Asia/Sakhalin',
  },
  {
    name: 'Central Pacific Standard Time',
    label: 'Solomon Is., New Caledonia',
    id: 'Pacific/Guadalcanal',
  },
  {
    name: 'Russia Time Zone 11',
    label: 'Anadyr, Petropavlovsk-Kamchatsky',
    id: 'Asia/Kamchatka',
  },
  {
    name: 'New Zealand Standard Time',
    label: 'Auckland, Wellington',
    id: 'Pacific/Auckland',
  },
  {
    name: 'UTC+12',
    label: 'Coordinated Universal Time+12',
    id: 'Etc/GMT-12',
  },
  {
    name: 'Fiji Standard Time',
    label: 'Fiji',
    id: 'Pacific/Fiji',
  },
  {
    name: 'Chatham Islands Standard Time',
    label: 'Chatham Islands',
    id: 'Pacific/Chatham',
  },
  {
    name: 'UTC+13',
    label: 'Coordinated Universal Time+13',
    id: 'Etc/GMT-13',
  },
  {
    name: 'Tonga Standard Time',
    label: "Nuku'alofa",
    id: 'Pacific/Tongatapu',
  },
  {
    name: 'Samoa Standard Time',
    label: 'Samoa',
    id: 'Pacific/Apia',
  },
  {
    name: 'Line Islands Standard Time',
    label: 'Kiritimati Island',
    id: 'Pacific/Kiritimati',
  },
] as const;
