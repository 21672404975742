import type { InjectionKey, Ref } from 'vue';

export const InjectionKey_IMEActive: InjectionKey<Readonly<Ref<boolean>>> =
  Symbol('IME Active State');

export function provideIMEActiveTracking() {
  // IME Active state provision
  const imeActive = ref<boolean>(false);

  const handleCompositionStart = () => {
    imeActive.value = true;
  };
  const handleCompositionEnd = () => {
    imeActive.value = false;
  };

  document.addEventListener('compositionstart', handleCompositionStart);
  document.addEventListener('compositionend', handleCompositionEnd);

  onBeforeUnmount(() => {
    document.removeEventListener('compositionstart', handleCompositionStart);
    document.removeEventListener('compositionend', handleCompositionEnd);
  });

  provide(InjectionKey_IMEActive, imeActive);
}
