/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DomainV0 } from './DomainV0';
import {
    DomainV0FromJSON,
    DomainV0FromJSONTyped,
    DomainV0ToJSON,
    DomainV0ToJSONTyped,
} from './DomainV0';
import type { OperationLogSearchQueryV0 } from './OperationLogSearchQueryV0';
import {
    OperationLogSearchQueryV0FromJSON,
    OperationLogSearchQueryV0FromJSONTyped,
    OperationLogSearchQueryV0ToJSON,
    OperationLogSearchQueryV0ToJSONTyped,
} from './OperationLogSearchQueryV0';
import type { UserV0 } from './UserV0';
import {
    UserV0FromJSON,
    UserV0FromJSONTyped,
    UserV0ToJSON,
    UserV0ToJSONTyped,
} from './UserV0';
import type { OperationLogDownloadDetailsV0 } from './OperationLogDownloadDetailsV0';
import {
    OperationLogDownloadDetailsV0FromJSON,
    OperationLogDownloadDetailsV0FromJSONTyped,
    OperationLogDownloadDetailsV0ToJSON,
    OperationLogDownloadDetailsV0ToJSONTyped,
} from './OperationLogDownloadDetailsV0';
import type { HeaderExportQueryV0 } from './HeaderExportQueryV0';
import {
    HeaderExportQueryV0FromJSON,
    HeaderExportQueryV0FromJSONTyped,
    HeaderExportQueryV0ToJSON,
    HeaderExportQueryV0ToJSONTyped,
} from './HeaderExportQueryV0';
import type { SearchQueryV0 } from './SearchQueryV0';
import {
    SearchQueryV0FromJSON,
    SearchQueryV0FromJSONTyped,
    SearchQueryV0ToJSON,
    SearchQueryV0ToJSONTyped,
} from './SearchQueryV0';
import type { CustomSearchPolicyV0 } from './CustomSearchPolicyV0';
import {
    CustomSearchPolicyV0FromJSON,
    CustomSearchPolicyV0FromJSONTyped,
    CustomSearchPolicyV0ToJSON,
    CustomSearchPolicyV0ToJSONTyped,
} from './CustomSearchPolicyV0';
import type { OperationTypeV0 } from './OperationTypeV0';
import {
    OperationTypeV0FromJSON,
    OperationTypeV0FromJSONTyped,
    OperationTypeV0ToJSON,
    OperationTypeV0ToJSONTyped,
} from './OperationTypeV0';

/**
 * 
 * @export
 * @interface OperationLogV0
 */
export interface OperationLogV0 {
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    apiVersion: string;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    attachmentFilename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    attachmentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    domainId: string;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    downloadFormat?: OperationLogV0DownloadFormatEnum | null;
    /**
     * 
     * @type {number}
     * @memberof OperationLogV0
     */
    errorCode?: number | null;
    /**
     * 
     * @type {HeaderExportQueryV0}
     * @memberof OperationLogV0
     */
    headerExportQuery?: HeaderExportQueryV0 | null;
    /**
     * 
     * @type {number}
     * @memberof OperationLogV0
     */
    httpResponseStatus: number;
    /**
     * Unique identifier for Operation Log
     * @type {string}
     * @memberof OperationLogV0
     */
    id: string;
    /**
     * 
     * @type {DomainV0}
     * @memberof OperationLogV0
     */
    initialDomain?: DomainV0 | null;
    /**
     * 
     * @type {CustomSearchPolicyV0}
     * @memberof OperationLogV0
     */
    initialSearchPolicy?: CustomSearchPolicyV0 | null;
    /**
     * 
     * @type {UserV0}
     * @memberof OperationLogV0
     */
    initialUser?: UserV0 | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    ipAddress: string;
    /**
     * 
     * @type {OperationLogDownloadDetailsV0}
     * @memberof OperationLogV0
     */
    operationLogDownloadDetails?: OperationLogDownloadDetailsV0 | null;
    /**
     * 
     * @type {OperationLogSearchQueryV0}
     * @memberof OperationLogV0
     */
    operationLogSearchQuery?: OperationLogSearchQueryV0 | null;
    /**
     * 
     * @type {OperationTypeV0}
     * @memberof OperationLogV0
     */
    operationType: OperationTypeV0;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    searchPolicyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    searchPolicyName?: string | null;
    /**
     * 
     * @type {SearchQueryV0}
     * @memberof OperationLogV0
     */
    searchQuery?: SearchQueryV0 | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    sessionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    targetUserEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    targetUserId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OperationLogV0
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    uidl?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OperationLogV0
     */
    uidls?: Array<string> | null;
    /**
     * 
     * @type {DomainV0}
     * @memberof OperationLogV0
     */
    updatedDomain?: DomainV0 | null;
    /**
     * 
     * @type {CustomSearchPolicyV0}
     * @memberof OperationLogV0
     */
    updatedSearchPolicy?: CustomSearchPolicyV0 | null;
    /**
     * 
     * @type {UserV0}
     * @memberof OperationLogV0
     */
    updatedUser?: UserV0 | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogV0
     */
    userId?: string | null;
}


/**
 * @export
 */
export const OperationLogV0DownloadFormatEnum = {
    Raw: 'raw',
    Readable: 'readable'
} as const;
export type OperationLogV0DownloadFormatEnum = typeof OperationLogV0DownloadFormatEnum[keyof typeof OperationLogV0DownloadFormatEnum];


/**
 * Check if a given object implements the OperationLogV0 interface.
 */
export function instanceOfOperationLogV0(value: object): value is OperationLogV0 {
    if (!('apiVersion' in value) || value['apiVersion'] === undefined) return false;
    if (!('domainId' in value) || value['domainId'] === undefined) return false;
    if (!('httpResponseStatus' in value) || value['httpResponseStatus'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('ipAddress' in value) || value['ipAddress'] === undefined) return false;
    if (!('operationType' in value) || value['operationType'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function OperationLogV0FromJSON(json: any): OperationLogV0 {
    return OperationLogV0FromJSONTyped(json, false);
}

export function OperationLogV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationLogV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'apiVersion': json['api_version'],
        'attachmentFilename': json['attachment_filename'] == null ? undefined : json['attachment_filename'],
        'attachmentId': json['attachment_id'] == null ? undefined : json['attachment_id'],
        'domainId': json['domain_id'],
        'downloadFormat': json['download_format'] == null ? undefined : json['download_format'],
        'errorCode': json['error_code'] == null ? undefined : json['error_code'],
        'headerExportQuery': json['header_export_query'] == null ? undefined : HeaderExportQueryV0FromJSON(json['header_export_query']),
        'httpResponseStatus': json['http_response_status'],
        'id': json['id'],
        'initialDomain': json['initial_domain'] == null ? undefined : DomainV0FromJSON(json['initial_domain']),
        'initialSearchPolicy': json['initial_search_policy'] == null ? undefined : CustomSearchPolicyV0FromJSON(json['initial_search_policy']),
        'initialUser': json['initial_user'] == null ? undefined : UserV0FromJSON(json['initial_user']),
        'ipAddress': json['ip_address'],
        'operationLogDownloadDetails': json['operation_log_download_details'] == null ? undefined : OperationLogDownloadDetailsV0FromJSON(json['operation_log_download_details']),
        'operationLogSearchQuery': json['operation_log_search_query'] == null ? undefined : OperationLogSearchQueryV0FromJSON(json['operation_log_search_query']),
        'operationType': OperationTypeV0FromJSON(json['operation_type']),
        'searchPolicyId': json['search_policy_id'] == null ? undefined : json['search_policy_id'],
        'searchPolicyName': json['search_policy_name'] == null ? undefined : json['search_policy_name'],
        'searchQuery': json['search_query'] == null ? undefined : SearchQueryV0FromJSON(json['search_query']),
        'sessionKey': json['session_key'] == null ? undefined : json['session_key'],
        'targetUserEmail': json['target_user_email'] == null ? undefined : json['target_user_email'],
        'targetUserId': json['target_user_id'] == null ? undefined : json['target_user_id'],
        'timestamp': (new Date(json['timestamp'])),
        'uidl': json['uidl'] == null ? undefined : json['uidl'],
        'uidls': json['uidls'] == null ? undefined : json['uidls'],
        'updatedDomain': json['updated_domain'] == null ? undefined : DomainV0FromJSON(json['updated_domain']),
        'updatedSearchPolicy': json['updated_search_policy'] == null ? undefined : CustomSearchPolicyV0FromJSON(json['updated_search_policy']),
        'updatedUser': json['updated_user'] == null ? undefined : UserV0FromJSON(json['updated_user']),
        'userEmail': json['user_email'] == null ? undefined : json['user_email'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
    };
}

export function OperationLogV0ToJSON(json: any): OperationLogV0 {
    return OperationLogV0ToJSONTyped(json, false);
}

export function OperationLogV0ToJSONTyped(value?: OperationLogV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'api_version': value['apiVersion'],
        'attachment_filename': value['attachmentFilename'],
        'attachment_id': value['attachmentId'],
        'domain_id': value['domainId'],
        'download_format': value['downloadFormat'],
        'error_code': value['errorCode'],
        'header_export_query': HeaderExportQueryV0ToJSON(value['headerExportQuery']),
        'http_response_status': value['httpResponseStatus'],
        'id': value['id'],
        'initial_domain': DomainV0ToJSON(value['initialDomain']),
        'initial_search_policy': CustomSearchPolicyV0ToJSON(value['initialSearchPolicy']),
        'initial_user': UserV0ToJSON(value['initialUser']),
        'ip_address': value['ipAddress'],
        'operation_log_download_details': OperationLogDownloadDetailsV0ToJSON(value['operationLogDownloadDetails']),
        'operation_log_search_query': OperationLogSearchQueryV0ToJSON(value['operationLogSearchQuery']),
        'operation_type': OperationTypeV0ToJSON(value['operationType']),
        'search_policy_id': value['searchPolicyId'],
        'search_policy_name': value['searchPolicyName'],
        'search_query': SearchQueryV0ToJSON(value['searchQuery']),
        'session_key': value['sessionKey'],
        'target_user_email': value['targetUserEmail'],
        'target_user_id': value['targetUserId'],
        'timestamp': ((value['timestamp']).toISOString()),
        'uidl': value['uidl'],
        'uidls': value['uidls'],
        'updated_domain': DomainV0ToJSON(value['updatedDomain']),
        'updated_search_policy': CustomSearchPolicyV0ToJSON(value['updatedSearchPolicy']),
        'updated_user': UserV0ToJSON(value['updatedUser']),
        'user_email': value['userEmail'],
        'user_id': value['userId'],
    };
}

