import type { IOverlayStore } from '@/store/overlay';

import { Overlay, OVERLAY_LOCK, useOverlayStore } from '@/store/overlay';

export type DrawerName =
  | 'new-policy'
  | 'edit-policy'
  | 'new-user'
  | 'edit-user'
  | 'operation-log'
  | 'email-view';

export class Drawer extends Overlay {
  /**
   * The type of drawer which is opened.
   */
  private _drawerType: DrawerName;

  /**
   * Optional data to be passed to the drawer component upon invocation.
   * The data type accepted depends on the drawer type being opened.
   */
  private _data: string | undefined;

  constructor(drawerType: DrawerName, data?: string) {
    super();
    this._drawerType = drawerType;
    this._data = data;
  }

  get drawerType() {
    return this._drawerType;
  }

  get data() {
    return this._data;
  }
}

export const useDrawerStore = defineStore('drawer', () => {
  const overlays = useOverlayStore();

  const openDrawerObject = computed<Drawer | undefined>(() => overlays.get('drawer'));
  const openDrawer = computed<DrawerName | undefined>(() => openDrawerObject.value?.drawerType);
  const data = computed<string | undefined>(() => openDrawerObject.value?.data);

  const active = computed<boolean>(() => overlays.get('drawer') !== undefined);
  const obscured = computed<boolean>(() => overlays.obscured('drawer'));

  /**
   * @param drawerName
   * @param data
   * @returns `false` if there is already a drawer open. `true` if the drawer was opened successfully
   */
  function attemptOpen(drawerName: DrawerName, data?: string): boolean {
    // Can't open a drawer if one is already open
    if (active.value) return false;

    // Can't open a drawer if a dialog is already open. Drawers must be first.
    if (overlays.count > 0) return false;

    const drawer = new Drawer(drawerName, data);

    return overlays.attemptPush('drawer', drawer);
  }

  /**
   * @param drawerName (optional) if provided, will try to close the specific named drawer.
   * @returns `true` if the drawer was closed successfully. `false` under certain conditions:
   *  - if a drawer wasn't open
   *  - the drawer name provided did not match the open drawer
   *  - the drawer is currently "disabled"
   */
  function attemptClose(drawerName?: DrawerName): boolean {
    // If the drawer isn't open
    if (!openDrawerObject.value) return false;

    // If the drawer name provided doesn't match the open drawer
    if (drawerName && openDrawerObject.value.drawerType !== drawerName) return false;

    return overlays.attemptPop('drawer');
  }

  function lock(lockType: OVERLAY_LOCK) {
    if (openDrawerObject.value) {
      return openDrawerObject.value.lock(lockType);
    } else return false;
  }

  function unlock(lockType: OVERLAY_LOCK) {
    if (openDrawerObject.value) {
      return openDrawerObject.value.unlock(lockType);
    } else return false;
  }

  const state = {
    active,
    obscured,

    openDrawer,
    data,

    attemptOpen,
    attemptClose,

    lock,
    unlock,
  } as const satisfies IOverlayStore;

  return state;
});

export default useDrawerStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDrawerStore, import.meta.hot));
}
