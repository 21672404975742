/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponsePayloadV0,
  OperationLogDownloadQueryV0,
  OperationLogDownloadResponseV0,
  OperationLogSearchQueryV0,
  OperationLogsV0,
} from '../models/index';
import {
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
    OperationLogDownloadQueryV0FromJSON,
    OperationLogDownloadQueryV0ToJSON,
    OperationLogDownloadResponseV0FromJSON,
    OperationLogDownloadResponseV0ToJSON,
    OperationLogSearchQueryV0FromJSON,
    OperationLogSearchQueryV0ToJSON,
    OperationLogsV0FromJSON,
    OperationLogsV0ToJSON,
} from '../models/index';

export interface DownloadFilteredOperationLogsRequest {
    operationLogDownloadQueryV0: OperationLogDownloadQueryV0;
}

export interface GetOperationLogsRequest {
    operationLogSearchQueryV0: OperationLogSearchQueryV0;
    operationLogSearchCode?: string;
}

/**
 * 
 */
export class OperationLogsApi extends runtime.BaseAPI {

    /**
     * This endpoint will fetch all operation logs for a given time period and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `operation-logs:download` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Download a filtered set of operation logs for a specific period of time.
     */
    async downloadFilteredOperationLogsRaw(requestParameters: DownloadFilteredOperationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationLogDownloadResponseV0>> {
        if (requestParameters['operationLogDownloadQueryV0'] == null) {
            throw new runtime.RequiredError(
                'operationLogDownloadQueryV0',
                'Required parameter "operationLogDownloadQueryV0" was null or undefined when calling downloadFilteredOperationLogs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/operation_logs/download`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperationLogDownloadQueryV0ToJSON(requestParameters['operationLogDownloadQueryV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationLogDownloadResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch all operation logs for a given time period and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `operation-logs:download` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Download a filtered set of operation logs for a specific period of time.
     */
    async downloadFilteredOperationLogs(requestParameters: DownloadFilteredOperationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationLogDownloadResponseV0> {
        const response = await this.downloadFilteredOperationLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will fetch all operation logs and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `operation-logs:download` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Download all operation logs.
     */
    async downloadOperationLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationLogDownloadResponseV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/operation_logs/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationLogDownloadResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will fetch all operation logs and send back a response with a location to download it in CSV format and some metadata about that file.  The user requires the permission `operation-logs:download` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Download all operation logs.
     */
    async downloadOperationLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationLogDownloadResponseV0> {
        const response = await this.downloadOperationLogsRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint returns all operation logs matching the given filters.  The user requires the permission `operation-logs:get-all` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Get a list of operation logs
     */
    async getOperationLogsRaw(requestParameters: GetOperationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationLogsV0>> {
        if (requestParameters['operationLogSearchQueryV0'] == null) {
            throw new runtime.RequiredError(
                'operationLogSearchQueryV0',
                'Required parameter "operationLogSearchQueryV0" was null or undefined when calling getOperationLogs().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['operationLogSearchCode'] != null) {
            queryParameters['operation_log_search_code'] = requestParameters['operationLogSearchCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/operation_logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperationLogSearchQueryV0ToJSON(requestParameters['operationLogSearchQueryV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationLogsV0FromJSON(jsonValue));
    }

    /**
     * This endpoint returns all operation logs matching the given filters.  The user requires the permission `operation-logs:get-all` to be able to call this endpoint. If the user has the permission `users:get`, the response will include user details in write entries for operation logs. If the user has the permission `search-policies:get`, the response will include search policy details in write entries for operation logs. If the user has the permission `domain:get`, the response will include domain details in write entries for operation logs.  Error Codes:  - N/A
     * Get a list of operation logs
     */
    async getOperationLogs(requestParameters: GetOperationLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationLogsV0> {
        const response = await this.getOperationLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
