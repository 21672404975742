import type { AdvancedOption } from './AdvancedOption';
import type { ExpandableValidationType } from './Validation';
import type {
  AnySearchConditionV0,
  AttachmentSearchConditionV0,
  SearchConditionV0,
  StorageStatusSearchConditionV0,
} from '@/api';
import type { TranslationKey } from '@/locales';
import type { DateRange } from '@/types/DateRange';
import type { SearchRangeOption } from '@/types/SearchRange';

import {
  AnySearchConditionV0FieldEnum,
  AnySearchConditionV0TypeEnum,
  AttachmentSearchConditionV0FieldEnum,
  BodySearchConditionV0FieldEnum,
  BodySearchConditionV0TypeEnum,
  CcSearchConditionV0FieldEnum,
  CcSearchConditionV0TypeEnum,
  EnvelopeFromEnvelopeToSearchConditionV0FieldEnum,
  EnvelopeFromEnvelopeToSearchConditionV0TypeEnum,
  EnvelopeFromSearchConditionV0FieldEnum,
  EnvelopeFromSearchConditionV0TypeEnum,
  EnvelopeToSearchConditionV0FieldEnum,
  EnvelopeToSearchConditionV0TypeEnum,
  FromSearchConditionV0FieldEnum,
  FromSearchConditionV0TypeEnum,
  StorageStatusSearchConditionV0FieldEnum,
  SubjectBodySearchConditionV0FieldEnum,
  SubjectBodySearchConditionV0TypeEnum,
  SubjectSearchConditionV0FieldEnum,
  SubjectSearchConditionV0TypeEnum,
  ToCcBccSearchConditionV0FieldEnum,
  ToCcBccSearchConditionV0TypeEnum,
  ToSearchConditionV0FieldEnum,
  ToSearchConditionV0TypeEnum,
} from '@/api';

export type SearchConditionWithId = SearchConditionV0 & { readonly id: number };

export interface SearchSettingsStoreType {
  dates: DateRange;
  conditions: SearchConditionWithId[];
  referrer: SearchReferrerOptions;
  anyCondition: string;
  rangeIndex: SearchRangeOption;
  status_error?: boolean;
}

export interface SearchStoreType extends SearchSettingsStoreType {
  dialog: {
    dates: DateRange;
    rangeIndex: SearchRangeOption;
    anyCondition: string;
  };
  // `temp` is used to store the dialog's state before it is made dirty by the user
  // this is necessary to determine dirtiness in the dialog
  temp: {
    dates: DateRange;
    rangeIndex: SearchRangeOption;
    anyCondition: string;
  };
}

/***************************
 * Search Condition Fields *
 ***************************/

export const SearchConditionFieldEnum = {
  ...AttachmentSearchConditionV0FieldEnum,
  ...AnySearchConditionV0FieldEnum,
  ...BodySearchConditionV0FieldEnum,
  ...CcSearchConditionV0FieldEnum,
  ...EnvelopeFromEnvelopeToSearchConditionV0FieldEnum,
  ...EnvelopeFromSearchConditionV0FieldEnum,
  ...EnvelopeToSearchConditionV0FieldEnum,
  ...FromSearchConditionV0FieldEnum,
  ...StorageStatusSearchConditionV0FieldEnum,
  ...SubjectBodySearchConditionV0FieldEnum,
  ...SubjectSearchConditionV0FieldEnum,
  ...ToCcBccSearchConditionV0FieldEnum,
  ...ToSearchConditionV0FieldEnum,
} as const satisfies Record<string, SearchConditionV0['field']>;

export type SearchConditionFieldEnum =
  (typeof SearchConditionFieldEnum)[keyof typeof SearchConditionFieldEnum];

/**
 * Type-check that the above enum exhaustively uses all the fields of the SearchConditionV0 type.
 *
 * If there is a type error in the block below, it means that SearchConditionFieldEnum does not exactly match SearchConditionV0.
 */
{
  type IsNever<T> = [T] extends [never] ? true : false;
  true satisfies IsNever<Exclude<SearchConditionV0['field'], SearchConditionFieldEnum>>;
  true satisfies IsNever<Exclude<SearchConditionFieldEnum, SearchConditionV0['field']>>;
}

// prettier-ignore
export const SearchConditionFieldsLabel = {
  [SearchConditionFieldEnum.Any]:                    `conditionFields.${SearchConditionFieldEnum.Any}`,
  [SearchConditionFieldEnum.From]:                    `conditionFields.${SearchConditionFieldEnum.From}`,
  [SearchConditionFieldEnum.To]:                      `conditionFields.${SearchConditionFieldEnum.To}`,
  [SearchConditionFieldEnum.Cc]:                      `conditionFields.${SearchConditionFieldEnum.Cc}`,
  [SearchConditionFieldEnum.ToCcBcc]:                 `conditionFields.${SearchConditionFieldEnum.ToCcBcc}`,
  [SearchConditionFieldEnum.EnvelopeFrom]:            `conditionFields.${SearchConditionFieldEnum.EnvelopeFrom}`,
  [SearchConditionFieldEnum.EnvelopeTo]:              `conditionFields.${SearchConditionFieldEnum.EnvelopeTo}`,
  [SearchConditionFieldEnum.EnvelopeFromEnvelopeTo]:  `conditionFields.${SearchConditionFieldEnum.EnvelopeFromEnvelopeTo}`,
  [SearchConditionFieldEnum.Subject]:                 `conditionFields.${SearchConditionFieldEnum.Subject}`,
  [SearchConditionFieldEnum.Body]:                    `conditionFields.${SearchConditionFieldEnum.Body}`,
  [SearchConditionFieldEnum.SubjectBody]:             `conditionFields.${SearchConditionFieldEnum.SubjectBody}`,
  [SearchConditionFieldEnum.HasAttachment]:           `conditionFields.${SearchConditionFieldEnum.HasAttachment}`,
  [SearchConditionFieldEnum.StorageStatus]:           `conditionFields.${SearchConditionFieldEnum.StorageStatus}`,
} as const satisfies Record<SearchConditionFieldEnum, TranslationKey>;

// This is the order in which these will be displayed:
export const SearchConditionFields: AdvancedOption<SearchConditionFieldEnum>[] = [
  {
    id: 'search-condition-basic',
    title: 'conditionFields.sections.basic',
    subsections: [
      {
        options: [
          {
            value: SearchConditionFieldEnum.EnvelopeFromEnvelopeTo,
            label: `conditionFields.${SearchConditionFieldEnum.EnvelopeFromEnvelopeTo}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.EnvelopeFromEnvelopeTo}`,
          },
          {
            value: SearchConditionFieldEnum.SubjectBody,
            label: `conditionFields.${SearchConditionFieldEnum.SubjectBody}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.SubjectBody}`,
          },
        ],
      },
    ],
  },
  {
    id: 'search-condition-advanced',
    title: 'conditionFields.sections.advanced',
    canToggle: true,
    subsections: [
      {
        options: [
          {
            value: SearchConditionFieldEnum.EnvelopeFrom,
            label: `conditionFields.${SearchConditionFieldEnum.EnvelopeFrom}`,
            isString: true,
            sublabel: `conditionFields.sublabels.${SearchConditionFieldEnum.EnvelopeFrom}`,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.EnvelopeFrom}`,
          },
          {
            value: SearchConditionFieldEnum.EnvelopeTo,
            label: `conditionFields.${SearchConditionFieldEnum.EnvelopeTo}`,
            isString: true,
            sublabel: `conditionFields.sublabels.${SearchConditionFieldEnum.EnvelopeTo}`,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.EnvelopeTo}`,
          },
          {
            value: SearchConditionFieldEnum.From,
            label: `conditionFields.${SearchConditionFieldEnum.From}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.From}`,
          },
          {
            value: SearchConditionFieldEnum.ToCcBcc,
            label: `conditionFields.${SearchConditionFieldEnum.ToCcBcc}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.ToCcBcc}`,
          },
          {
            value: SearchConditionFieldEnum.To,
            label: `conditionFields.${SearchConditionFieldEnum.To}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.To}`,
          },
          {
            value: SearchConditionFieldEnum.Cc,
            label: `conditionFields.${SearchConditionFieldEnum.Cc}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.Cc}`,
          },
        ],
      },
      {
        options: [
          {
            value: SearchConditionFieldEnum.Subject,
            label: `conditionFields.${SearchConditionFieldEnum.Subject}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.Subject}`,
          },
          {
            value: SearchConditionFieldEnum.Body,
            label: `conditionFields.${SearchConditionFieldEnum.Body}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.Body}`,
          },
          {
            value: SearchConditionFieldEnum.HasAttachment,
            label: `conditionFields.${SearchConditionFieldEnum.HasAttachment}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.HasAttachment}`,
          },
        ],
      },
      {
        options: [
          {
            value: SearchConditionFieldEnum.StorageStatus,
            label: `conditionFields.${SearchConditionFieldEnum.StorageStatus}`,
            isString: true,
            description: `conditionFields.descriptions.${SearchConditionFieldEnum.StorageStatus}`,
          },
        ],
      },
    ],
  },
];

/************************************
 * Text-Only Search Condition Types *
 ************************************/

export type TextSearchCondition = Exclude<
  SearchConditionV0,
  StorageStatusSearchConditionV0 | AttachmentSearchConditionV0 | AnySearchConditionV0
>;

export type TextSearchConditionField = TextSearchCondition['field'];

export function isTextTypeSearchCondition(
  condition: SearchConditionV0,
): condition is TextSearchCondition {
  return (
    condition.field !== 'has_attachment' &&
    condition.field !== 'storage_status' &&
    condition.field !== 'any'
  );
}

export function isAnyTypeSearchCondition(
  condition: SearchConditionV0,
): condition is AnySearchConditionV0 {
  return condition.field === 'any';
}

/**
 * Purposefully excluding Attachment and Storage Status here,
 * to make this a list of only text-type search conditions
 */
export const SearchConditionTextTypeEnum = {
  ...AnySearchConditionV0TypeEnum,
  ...BodySearchConditionV0TypeEnum,
  ...CcSearchConditionV0TypeEnum,
  ...EnvelopeFromEnvelopeToSearchConditionV0TypeEnum,
  ...EnvelopeFromSearchConditionV0TypeEnum,
  ...EnvelopeToSearchConditionV0TypeEnum,
  ...FromSearchConditionV0TypeEnum,
  ...SubjectBodySearchConditionV0TypeEnum,
  ...SubjectSearchConditionV0TypeEnum,
  ...ToCcBccSearchConditionV0TypeEnum,
  ...ToSearchConditionV0TypeEnum,
} as const satisfies Record<string, TextSearchCondition['type']>;
export type SearchConditionTextTypeEnum =
  (typeof SearchConditionTextTypeEnum)[keyof typeof SearchConditionTextTypeEnum];

/**
 * Type-check that the above enum exhaustively references all the textual types of SearchConditionV0.
 *
 * If there is a type error in the block below, it means that SearchConditionTextV0TypeEnum
 * does not exactly match all text types within SearchConditionV0.
 */
{
  type IsNever<T> = [T] extends [never] ? true : false;
  true satisfies IsNever<Exclude<TextSearchCondition['type'], SearchConditionTextTypeEnum>>;
  true satisfies IsNever<Exclude<SearchConditionTextTypeEnum, TextSearchCondition['type']>>;
}

// This is the order in which these will be displayed:
// prettier-ignore
export const SearchConditionTextTypes = {
  [SearchConditionTextTypeEnum.Contain]:        `conditionTypes.${SearchConditionTextTypeEnum.Contain}`,
  [SearchConditionTextTypeEnum.NotContain]:     `conditionTypes.${SearchConditionTextTypeEnum.NotContain}`,
  [SearchConditionTextTypeEnum.ContainNotOnly]: `conditionTypes.${SearchConditionTextTypeEnum.ContainNotOnly}`,
  [SearchConditionTextTypeEnum.Match]:          `conditionTypes.${SearchConditionTextTypeEnum.Match}`,
  [SearchConditionTextTypeEnum.NotMatch]:       `conditionTypes.${SearchConditionTextTypeEnum.NotMatch}`,
} as const satisfies Record<SearchConditionTextTypeEnum, TranslationKey>;

// prettier-ignore
export const SearchConditionValidationTypes = {
  [SearchConditionTextTypeEnum.Contain]:        'any',
  [SearchConditionTextTypeEnum.NotContain]:     'any',
  [SearchConditionTextTypeEnum.ContainNotOnly]: 'any',
  [SearchConditionTextTypeEnum.Match]:          'email',
  [SearchConditionTextTypeEnum.NotMatch]:       'email',
} as const satisfies Record<SearchConditionTextTypeEnum, ExpandableValidationType>;

export const SearchConditionTextTypeGroups = {
  textTypeGroupDefault: [
    SearchConditionTextTypeEnum.Contain,
    SearchConditionTextTypeEnum.NotContain,
    SearchConditionTextTypeEnum.Match,
    SearchConditionTextTypeEnum.NotMatch,
  ],
  textTypeGroupContainOnly: [SearchConditionTextTypeEnum.Contain],
  textTypeGroupContainsOnly: [
    SearchConditionTextTypeEnum.Contain,
    SearchConditionTextTypeEnum.NotContain,
  ],
  textTypeGroupContainsExcept: [
    SearchConditionTextTypeEnum.Contain,
    SearchConditionTextTypeEnum.NotContain,
    SearchConditionTextTypeEnum.ContainNotOnly,
    SearchConditionTextTypeEnum.Match,
    SearchConditionTextTypeEnum.NotMatch,
  ],
} as const satisfies Record<string, readonly SearchConditionTextTypeEnum[]>;

export type SearchConditionTextTypeGroupKey = keyof typeof SearchConditionTextTypeGroups;

export type SearchReferrerOptions = '' | 'history' | 'tadrill';
