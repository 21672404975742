import type { IOverlayStore, OverlayType } from '@/store/overlay';
import type { AttachmentInfoWithData, AttachmentInfoWithFailureReason } from '@/types/Attachment';

import useOverlayStore, { Overlay, OVERLAY_LOCK } from '@/store/overlay';

export class Dialog<T extends DialogType> extends Overlay {
  /**
   * The type of dialog which is opened.
   */
  private _dialogType: T;

  /**
   * Optional data to be passed to the dialog component upon invocation.
   * The data type accepted depends on the dialog type being opened.
   */
  private _data: DialogDataType<T>;

  constructor(dialogType: T, data: DialogDataType<T>) {
    super();
    this._dialogType = dialogType;
    this._data = data;
  }

  get dialogType() {
    return this._dialogType;
  }

  get data() {
    return this._data;
  }
}

/**
 * Dialog definitions. Each dialog should have a unique name, and the data type of payload data that it requires.
 * If a certain dialog doesn't require any data, the data type should be `undefined`.
 */
type DialogDefinitionMap = {
  AttachmentPreviewDialog: AttachmentInfoWithData;
  AttachmentPreviewFailedDialog: AttachmentInfoWithFailureReason;
  DeleteDialog: string;
  DiscardDialog: undefined;
  DownloadEmailHeadersDialog: undefined;
  DownloadOperationLogDialog: undefined;
  EditSearchDialog: undefined;
  FailedOnboardingDialog: undefined;
  FeedbackDialog: undefined;
  OperationLogSettingsDialog: undefined;
};

export type DialogType = keyof DialogDefinitionMap;

type DialogDataType<T extends DialogType> = DialogDefinitionMap[T];

// Computed type to get keys of DialogDefinitionMap where the value is not undefined
type DialogTypesWithData = {
  [K in keyof DialogDefinitionMap]: DialogDefinitionMap[K] extends undefined ? never : K;
}[keyof DialogDefinitionMap];

export const useDialogStore = defineStore('dialog', () => {
  const overlays = useOverlayStore();

  const hasOpenDialog = computed(() => {
    if (overlays.count === 0) return false;
    else if (overlays.count === 1) return overlays.topKey !== 'drawer';
    else return true; // overlays.count > 1, which means we have at least 1 dialog open
  });

  const active = (id: DialogType): boolean => !!overlays.get(id);
  const disabled = (id: DialogType): boolean => !!overlays.get(id)?.locked;
  const getInvoker = (id: DialogType): OverlayType | undefined => overlays.under(id);
  const obscured = (id: DialogType) => overlays.obscured(id);

  function getData<T extends DialogType>(id: T): DialogDataType<T> | undefined {
    return overlays.get(id)?.data;
  }

  async function attemptOpen<T extends Exclude<DialogType, DialogTypesWithData>>(
    id: T,
  ): Promise<boolean> {
    const newDialog = new Dialog(id, undefined);

    return attemptOpenDialog(id, newDialog);
  }

  async function attemptOpenWithData<T extends DialogTypesWithData>(
    id: T,
    data: DialogDataType<T>,
  ): Promise<boolean> {
    const newDialog = new Dialog(id, data);

    return attemptOpenDialog(id, newDialog);
  }

  async function attemptOpenDialog<T extends DialogType>(
    dialogId: T,
    newDialog: Dialog<T>,
  ): Promise<boolean> {
    const success = overlays.attemptPush(dialogId, newDialog);

    if (!success) return false;

    // A temporary 100 ms dialog lock is needed here because of a race condition.
    // Without it, v-click-outside is being triggered as soon as a dialog opens and closes the dialog immediately
    lock(dialogId, OVERLAY_LOCK.TEMPORARY_ANIMATION_DELAY);

    await delay(100);

    unlock(dialogId, OVERLAY_LOCK.TEMPORARY_ANIMATION_DELAY);

    return true;
  }

  /**
   * @param dialogName the specific dialog to close.
   * @returns `true` if the dialog was closed successfully. `false` under certain conditions:
   *  - if the dialog with the given name wasn't open
   *  - the dialog is currently "locked" (can't be closed)
   */
  function attemptClose(dialogName: DialogType): boolean {
    return overlays.attemptPop(dialogName);
  }

  function lock(id: DialogType, lockType: OVERLAY_LOCK): boolean {
    const dialog = overlays.get(id);
    if (dialog) {
      return dialog.lock(lockType);
    } else return false;
  }

  function unlock(id: DialogType, lockType: OVERLAY_LOCK): boolean {
    const dialog = overlays.get(id);
    if (dialog) {
      return dialog.unlock(lockType);
    } else return false;
  }

  const state = {
    hasOpenDialog,
    active,
    disabled,
    obscured,

    getInvoker,
    getData,

    attemptOpen,
    attemptOpenWithData,
    attemptClose,

    lock,
    unlock,
  } as const satisfies IOverlayStore;

  return state;
});

export default useDialogStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDialogStore, import.meta.hot));
}
