import type { TranslationKey } from '@/locales';
import type { DateRange } from '@/types/DateRange';
import type { OperationLogConditions } from '@/types/OperationLog';
import type { RowLimitOption } from '@/types/Pagination';
import type { SearchRangeOption } from '@/types/SearchRange';

import { OperationLogSearchQueryV0SortByTimestampEnum, OperationTypeV0 } from '@/api';
import { generateQueryString, parseQueryString } from '@/utils/parameters/OperationLogSearch';

import dayjs from 'dayjs';
import { ZodError } from 'zod';

export interface OperationLogSettingsState {
  searchCriteria: OperationLogConditions & {
    limit: RowLimitOption; // Redefine it to be more narrow
  };
  conditions: OperationLogConditions;
}

const getDefaultCriteria = (): OperationLogConditions => {
  const tenant = useTenantStore();

  const operationTypes = new Set<OperationTypeV0>([
    OperationTypeV0.CreateSearchPolicy,
    OperationTypeV0.CreateUser,
    OperationTypeV0.DefrostMail,
    OperationTypeV0.DeleteSearchPolicy,
    OperationTypeV0.DeleteUser,
    OperationTypeV0.DownloadMail,
    OperationTypeV0.DownloadMailAttachment,
    OperationTypeV0.GetAccessToken,
    OperationTypeV0.GetMailDetails,
    // OperationTypeV0.GetOperationLogs,
    OperationTypeV0.Logout,
    OperationTypeV0.SearchArchive,
    // OperationTypeV0.PreviewMailAttachment,
    OperationTypeV0.UpdateSearchPolicy,
    OperationTypeV0.UpdateUser,
  ]);

  if (tenant.enabledFeatures.has(FEATURE_FLAG.search_result_csv_export)) {
    operationTypes.add(OperationTypeV0.ExportHeaders);
  }

  if (tenant.enabledFeatures.has(FEATURE_FLAG.mutable_ip_restrictions)) {
    operationTypes.add(OperationTypeV0.UpdateDomain);
  }

  return {
    rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
    userOption: 'all',
    operationTypes,
    start: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone).start,
    end: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone).end,
    userEmails: undefined,
  };
};

export const useOperationLogSettingsStore = defineStore('operationLogSettings', {
  state: (): OperationLogSettingsState => ({
    /**
     * This represents the current search criteria that were most recently used in a search
     */
    searchCriteria: {
      ...getDefaultCriteria(),

      // API-specific criteria:
      limit: DEFAULT_LIMIT_VALUE,
      skiptoken: undefined,
      backtoken: undefined,
      sortByTimestamp: OperationLogSearchQueryV0SortByTimestampEnum.Desc,
    },
    /**
     * This represents the state of the input elements in the Conditions modal.
     * When a search is made, these values are copied to `searchCriteria`.
     */
    conditions: getDefaultCriteria(),
  }),

  getters: {
    conditionUsers(state): string[] {
      return state.conditions.userEmails ? [...state.conditions.userEmails] : [];
    },

    /**
     * Object key order matters here as it will determine the order of the keys in the query string.
     */
    queryString(state) {
      const query = generateQueryString({
        dates: { start: state.searchCriteria.start, end: state.searchCriteria.end },
        limit: state.searchCriteria.limit,
        rangeIndex: state.searchCriteria.rangeIndex,
        userEmails: state.searchCriteria.userEmails,
        operationTypes: state.searchCriteria.operationTypes,
      });

      return query;
    },

    // validation
    errorMessage(state): TranslationKey | '' {
      if (dayjs(state.conditions.start).isAfter(state.conditions.end)) {
        return 'validation.dateRangeStartAfterEnd';
      } else {
        return '';
      }
    },
  },

  actions: {
    getDateRangeById(id: SearchRangeOption, timezone: string): DateRange {
      return SearchRanges[id].getDateRange(timezone);
    },

    /**
     * This parses the query string for the operation log page's saved search settings.
     * General rule is to allow other unused keys to pass through,
     * but if there is a malformed value for a known key, we throw away the query string.
     * @param queryString Defaults to `location.search`
     */
    parseOperationLogSearchQueryString(queryString: string, timezone: string): void {
      logger.debug('we are parsing the query string: ' + queryString);

      if (queryString.length <= 1) return; // accounts for possible '?' prefix

      try {
        // Will throw on invalid data
        const parseResult = parseQueryString(queryString, timezone);

        if (parseResult === null) {
          return;
        }

        this.searchCriteria.rangeIndex = parseResult.rangeIndex;

        if (parseResult.rangeIndex === 'custom' && parseResult.dates) {
          this.searchCriteria.start = parseResult.dates.start;
          this.searchCriteria.end = parseResult.dates.end;
        } else {
          // TODO move this into serde
          const dates = SearchRanges[parseResult.rangeIndex].getDateRange(timezone);
          this.searchCriteria.start = dates.start;
          this.searchCriteria.end = dates.end;
        }

        if (parseResult.limit) {
          this.searchCriteria.limit = parseResult.limit;
        }

        if (parseResult.userEmails.size > 0) {
          this.searchCriteria.userEmails = parseResult.userEmails;
          this.searchCriteria.userOption = 'only';
        } else {
          this.searchCriteria.userEmails = undefined;
          this.searchCriteria.userOption = 'all';
        }

        if (parseResult.operationTypes.size > 0) {
          this.searchCriteria.operationTypes = parseResult.operationTypes;
        } else {
          this.searchCriteria.operationTypes = new Set(AllOperationTypes);
        }

        // From release v1.33, OperationTypeV0.ExportHeaders is now a valid operation type.
        // While we wait to full GA release this behind the feature flag,
        //   if an unprivileged user provides `exportHeaders` in the URL, we should throw an error.
        const { tenant } = useStores('tenant');
        if (
          !tenant.enabledFeatures.has(FEATURE_FLAG.search_result_csv_export) &&
          this.searchCriteria.operationTypes.has(OperationTypeV0.ExportHeaders)
        ) {
          throw new NobitaParseError(`exportHeaders is not a valid operation type.`);
        }

        if (
          !tenant.enabledFeatures.has(FEATURE_FLAG.mutable_ip_restrictions) &&
          this.searchCriteria.operationTypes.has(OperationTypeV0.UpdateDomain)
        ) {
          throw new NobitaParseError(`updateDomain is not a valid operation type.`);
        }
      } catch (e: unknown) {
        if (e instanceof ZodError) {
          logger.debug('A known Zod Error has occurred');
          logger.debug(e.message);
        } else if (e instanceof NobitaParseError) {
          logger.debug('Nobita Parse Error:', e.message);
        } else {
          // This was a real error we were not expecting, so throw it as such
          throw e;
        }

        throw new NobitaParseError('Could not parse query parameters.'); // include {cause: e} once supported
      }
    },

    // dates & date range
    async setDates(params: DateRange): Promise<void> {
      this.conditions.start = params.start;
      this.conditions.end = params.end;
    },

    setUserOption(option: 'all' | 'only', isCondition: boolean = false): void {
      if (isCondition) {
        this.conditions.userOption = option;
      } else {
        this.searchCriteria.userOption = option;
      }
    },

    setRangeIndex(index: SearchRangeOption): void {
      this.conditions.rangeIndex = index;
    },

    setLimit(param: RowLimitOption): void {
      this.searchCriteria.limit = param;
    },

    // users
    setUsers(params: Set<string> | null, isCondition: boolean = false): void {
      if (isCondition) {
        this.conditions.userEmails = new Set(params);
      } else {
        this.searchCriteria.userEmails = new Set(params);
      }
    },

    addUser(value: string, isCondition: boolean = false): void {
      if (isCondition) {
        if (this.conditions.userEmails) {
          this.conditions.userEmails.add(value);
        } else {
          this.conditions.userEmails = new Set([value]);
        }
      } else {
        if (this.searchCriteria.userEmails) {
          this.searchCriteria.userEmails.add(value);
        } else {
          this.searchCriteria.userEmails = new Set([value]);
        }
      }
    },

    deleteUser(value: string, isCondition: boolean = false): void {
      if (isCondition) {
        if (this.conditions.userEmails) {
          this.conditions.userEmails.delete(value);
        }
      } else {
        if (this.searchCriteria.userEmails) this.searchCriteria.userEmails.delete(value);
      }
    },

    setConditionsOperationsTypes(params: Set<OperationTypeV0>): void {
      this.conditions.operationTypes = new Set(params);
    },

    addConditionsOperationsTypes(params: Set<OperationTypeV0>): void {
      [...params].every((x) => this.conditions.operationTypes.add(x));

      // FIXME
      // add missing API for UpdateDomain
      // this.conditions.operationTypes.add(OperationTypeV0.UpdateDomain);
    },

    removeConditionsOperationsTypes(params: Set<OperationTypeV0>): void {
      [...params].every((x) => this.conditions.operationTypes.delete(x));

      // FIXME
      // add missing API for UpdateDomain
      // this.conditions.operationTypes.delete(OperationTypeV0.UpdateDomain);
    },

    setConditions(): void {
      this.searchCriteria.userOption = this.conditions.userOption;
      this.searchCriteria.rangeIndex = this.conditions.rangeIndex;
      this.searchCriteria.operationTypes =
        this.conditions.operationTypes.size > 0
          ? new Set(this.conditions.operationTypes)
          : new Set(AllOperationTypes);
      this.searchCriteria.start = this.conditions.start;
      this.searchCriteria.end = this.conditions.end;
      this.searchCriteria.userEmails =
        this.conditions.userOption === 'only' ? this.conditions.userEmails : undefined;
    },

    /**
     * This reverts the modal's conditions back to the last search's conditions.
     */
    async cleanConditions(): Promise<void> {
      this.conditions.userOption = this.searchCriteria.userOption;
      this.conditions.rangeIndex = this.searchCriteria.rangeIndex;
      this.conditions.operationTypes = new Set(this.searchCriteria.operationTypes);

      this.conditions.start = this.searchCriteria.start;
      this.conditions.end = this.searchCriteria.end;

      this.conditions.userEmails = this.searchCriteria.userEmails
        ? new Set(this.searchCriteria.userEmails)
        : undefined;
    },

    /**
     * This resets the modal's conditions to the default values (from first load).
     */
    resetConditions(): void {
      this.conditions = getDefaultCriteria();
    },

    discardChanges(): void {
      // This timeout is required to let the dialog close animation finish before we revert the changes.
      setTimeout(() => {
        // revert the dirty changes here back to the last search's conditions
        this.cleanConditions();
      }, TRANSITION_MS);
    },
  },
});

export default useOperationLogSettingsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOperationLogSettingsStore, import.meta.hot));
}
