<template>
  <BaseDialog :id title="" :custom-close-handler="cancelHandler">
    <form id="FeedbackForm" class="FeedbackDialogMain" @submit.prevent="feedbackHandler">
      <BaseLoader v-if="isFeedbackLoading" />
      <FeedbackThankMessage v-else-if="isFeedbackSubmitted" :is-angry="isNotHappy" />
      <template v-else>
        <h3 class="FeedbackDialogTitle">{{ $t('feedback.title') }}</h3>

        <fieldset class="Fieldset">
          <ul class="MoodList">
            <li v-for="(mood, val, idx) in FEEDBACK_MOODS" :key="`feedback-moods-${val}`">
              <RadioMoodField
                v-model="feedback.score"
                :input-id="mood"
                :input-value="(idx + 1) as FEEDBACK_MOOD_VALUE"
              />
            </li>
          </ul>
        </fieldset>

        <template v-if="isNotHappy">
          <p class="FeedbackDialogMessage">
            {{ $t('feedback.veryAngryMessage') }}
            <br />
            {{ $t('feedback.veryAngryMessage2') }}
          </p>

          <fieldset class="Fieldset">
            <ul class="FeaturesList">
              <li v-for="(feature, idx) in FEEDBACK_FEATURES" :key="`feedback-features-${idx}`">
                <RadioButtonField
                  v-model="feedback.category"
                  :input-id="`feedback-${feature}`"
                  name="feedback-features"
                  :input-value="feature"
                  :label="`feedback.features.${feature}`"
                />
              </li>
            </ul>
          </fieldset>
        </template>

        <TextareaField
          v-if="feedback.score !== null"
          v-model="feedback.comment"
          class="FeedbackMessageField"
          name="feedback-message"
          placeholder="feedback.messageFieldPlaceholder"
        />
      </template>
    </form>

    <template #footer>
      <FeedbackToolbar
        :is-submitted="isFeedbackSubmitted"
        :has-feedback="feedback.score !== null"
        form-id="FeedbackForm"
        @close="cancelHandler"
        @cancel="cancelHandler"
      />
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
import { Feedback, FEEDBACK_FEATURES, FEEDBACK_MOOD_VALUE, FEEDBACK_MOODS } from '@/types/Feedback';

const { tenant, snackbars, dialog } = useStores('tenant', 'snackbars', 'dialog');
const { sendFeedback } = useFeedbackHelper();

const id = 'FeedbackDialog';

// feedback
const feedback = reactive<Feedback>(new Feedback());

// moods
const isNotHappy = computed(
  () =>
    feedback.score !== null &&
    (feedback.score === FEEDBACK_MOOD_VALUE.veryAngry ||
      feedback.score === FEEDBACK_MOOD_VALUE.angry),
);

// submit
const isFeedbackLoading = ref<boolean>(false);
const isFeedbackSubmitted = ref<boolean>(false);

function resetFeedback(): void {
  feedback.comment = undefined;
  feedback.score = null;
  feedback.category = null;
}

async function cancelHandler(): Promise<boolean> {
  const success = dialog.attemptClose(id);
  if (!success) return false;

  await delay(TRANSITION_MS);

  isFeedbackLoading.value = false;
  isFeedbackSubmitted.value = false;
  resetFeedback();
  return true;
}

async function feedbackHandler() {
  isFeedbackLoading.value = true;
  feedback.tenant = tenant.loginDomainName;
  feedback.email = tenant.email;
  feedback.comment = feedback.comment || undefined;

  try {
    await sendFeedback(feedback);
    isFeedbackSubmitted.value = true;
    resetFeedback();
  } catch (e) {
    logger.error(e);
    snackbars.addWarningSnackbar();
  }
  isFeedbackLoading.value = false;
}
</script>

<style scoped lang="scss">
.FeedbackDialogMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 378px;
  padding: 32px 26px;
}

.FeedbackDialogTitle {
  color: black(87);
  font-size: 20px;
  font-weight: $font-weight-normal;
  line-height: 1.4;
  margin: 0;
}

.FeedbackDialogMessage {
  color: black(87);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 24px;
  text-align: center;
}

.MoodList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 40px 0;

  & li {
    margin: 0 11px;
  }
}

.FeaturesList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;

  & li {
    padding: 0 4px;
  }
}

.Fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
</style>
