import type { SelectorOption } from '@/components/atoms/selectors/BaseSelector.vue';
import type { Dayjs } from 'dayjs';

import { WindowsTimezones } from '../data/windowsZones';

import dayjs from 'dayjs';

/**
 * TYPES
 */

// tz database (olson) keys; e.g. `Asia/Tokyo`
export type TimezoneId = `${string}/${string}`;

// tz database keys that are defined in `windowsZones.ts`
export type WindowsTimezoneId = (typeof WindowsTimezones)[number]['id'];

export interface Timezone {
  id: WindowsTimezoneId;
  name: string;
  label: string;
}

/**
 * CONSTS
 */

const ABBREVIATION_OVERRIDES = {
  'Asia/Tokyo': 'JST',
  'Asia/Taipei': 'TST',
} as const satisfies Partial<Record<WindowsTimezoneId, string>>;

/**
 * FUNCTIONS
 */

/**
 * Given an OlsonDB timezone ID like `Asia/Tokyo` and a Dayjs date object, return the timezone abbreviation.
 * @param timezoneId An OlsonDB timezone ID like `Asia/Tokyo`
 * @param dt A Dayjs date object
 * @returns The timezone abbreviation like JST, EST, EDT, or `undefined` if an appropriate abbreviation is not found.
 */
export function getTimezoneAbbreviation(timezoneId: TimezoneId, dt: Dayjs): string | undefined {
  if (timezoneId in ABBREVIATION_OVERRIDES)
    return ABBREVIATION_OVERRIDES[timezoneId as keyof typeof ABBREVIATION_OVERRIDES];
  else {
    const generatedAbbr = dt.tz(timezoneId).format('z');
    if (generatedAbbr.startsWith('GMT+') || generatedAbbr.startsWith('GMT-')) return undefined;
    else return generatedAbbr;
  }
}

/**
 * Given an OlsonDB timezone ID like `Asia/Tokyo`, return the corresponding Windows timezone object.
 * @param timezoneId an OlsonDB timezone ID like `Asia/Tokyo
 * @returns The Windows timezone name, label, and ID of the timezone, or `undefined` if the timezone is not found.
 */
export function getTimezoneFromId(timezoneId: TimezoneId): Timezone | undefined {
  return WindowsTimezones.find((zone) => zone.id === timezoneId);
}

/**
 * Gets a list of all timezones supported by Nobita, sorted by UTC offset.
 * Includes labels and offset data computed based on the time of function invocation.
 */
export function getNobitaTimezones() {
  const { formatTimezoneLong } = useFormatters();

  const now = dayjs();

  return WindowsTimezones.map((zone, index) => ({
    zone,
    index,
    zoneNow: now.tz(zone.id),
  }))
    .sort((a, b) => a.zoneNow.utcOffset() - b.zoneNow.utcOffset() || a.index - b.index)
    .map(({ zone, zoneNow }) => ({
      offset: zoneNow.utcOffset(),
      value: zone.id,
      label: formatTimezoneLong(zone.id, now),
      isString: true,
    })) satisfies SelectorOption<WindowsTimezoneId>[];
}
