import type { DomainV0 } from '@/api/models';
import type { TranslationKey } from '@/locales';
import type { IpRestrictionsOption } from '@/types/GeneralSettings';

export interface DomainState {
  idp: string;
  allowAll: IpRestrictionsOption;
  timezone: string;
  domainIpRestrictions: string;
  ipRestrictions: string[];
  settings: {
    allowAll: IpRestrictionsOption;
    timezone: string;
    ipRestrictions: string;
  };
  errorMessage?: TranslationKey | string;
}

export const useDomainStore = defineStore('domain', {
  state: (): DomainState => ({
    idp: '',
    allowAll: 'all',
    timezone: '',
    domainIpRestrictions: '',
    ipRestrictions: [],
    settings: {
      allowAll: 'all',
      timezone: '',
      ipRestrictions: '',
    },
    errorMessage: undefined,
  }),

  getters: {
    isEditing(): boolean {
      return (
        this.settings.timezone !== this.timezone ||
        this.settings.allowAll !== this.allowAll ||
        this.settings.ipRestrictions !== this.domainIpRestrictions
      );
    },

    isIpRestrictionsFieldValid(): boolean {
      return (
        this.settings.allowAll === 'all' ||
        (this.ipRestrictions.length > 0 && this.ipRestrictions.length <= MAX_IP_RESTRICTIONS)
      );
    },
  },

  actions: {
    setErrorMessage(message: TranslationKey | string | undefined) {
      this.errorMessage = message;
    },

    setAllowIpsOption(param: IpRestrictionsOption): void {
      this.settings.allowAll = param;
    },

    updateDomain(timezone: string, ipRestrictions: string[]): void {
      this.timezone = timezone;
      this.ipRestrictions = ipRestrictions;
      this.settings.ipRestrictions = ipRestrictions.join('\n');
      this.domainIpRestrictions = ipRestrictions.join('\n');
    },

    updateIpRestrictions(params: string): void {
      this.settings.ipRestrictions = params;
      const allowedIpsArray = params.split('\n');

      this.ipRestrictions = allowedIpsArray.map((x) => x.trim()).filter((x) => x !== '');
    },

    updateSettings(timezone: string, ipRestrictions: string): void {
      this.settings.timezone = timezone;
      this.settings.ipRestrictions = ipRestrictions;
    },

    updateTimezoneSettings(timezone: string): void {
      this.settings.timezone = timezone;
    },

    updateIpRestrictionsSettings(ipRestrictions: string): void {
      this.settings.ipRestrictions = ipRestrictions;
    },

    resetSettings(): void {
      this.settings.timezone = this.timezone;
      this.settings.ipRestrictions = this.domainIpRestrictions;
      this.settings.allowAll = this.allowAll;
    },

    initDomain(domain: DomainV0): void {
      this.idp = domain.idp;
      this.timezone = domain.timezone;
      this.domainIpRestrictions = domain.ipRestrictions ? domain.ipRestrictions.join('\n') : '';
      this.ipRestrictions = domain.ipRestrictions ? [...domain.ipRestrictions] : [];
      this.allowAll = domain.ipRestrictions
        ? domain.ipRestrictions.length === 0
          ? 'all'
          : 'only'
        : 'all';
      this.resetSettings();
    },
  },
});

export default useDomainStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDomainStore, import.meta.hot));
}
